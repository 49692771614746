import { WYSIWYG_EDITOR } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'set-order-credit-notes'
  const MODULE_NAME_CLONE = 'cloneData'
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const creditNotesFields = {
    setting_orders_credit_notes_template_email: {
      type: WYSIWYG_EDITOR,
      label: 'Email',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
    setting_orders_credit_notes_template_pdf: {
      type: WYSIWYG_EDITOR,
      label: 'PDF version',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
  }
  return {
    creditNotesFields,
    ACCESS_ABILITY_SETTINGS_ORDERS,
    MODULE_NAME,
    MODULE_NAME_CLONE,
  }
}
