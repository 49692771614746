<template>
  <div>
    <skeleton v-if="loading" />
    <credit-notes-form
      v-else
      ref="credit-notes-form"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import creditNotesForm from '@/views/settings/orders/credit-notes/components/CreditNotesForm.vue'
import store from '@/store'
import creditNotesModule from '@/store/settings/orders/credit-notes'
import { onUnmounted } from '@vue/composition-api'
import Skeleton from '@/views/settings/orders/credit-notes/components/Skeleton.vue'
import config from '@/views/settings/orders/credit-notes/creditNotesConfig'

export default {
  name: 'CreditNotes',
  components: { Skeleton, creditNotesForm },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    console.log('GALAMi')
    this.$emit('updateMenu', 'settings-orders-credit-notes')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['credit-notes-form']) this.$refs['credit-notes-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getCreditNotes`).then(res => {
        const { data } = res.data
        // const obj = {}
        // data.forEach(({ key, value }) => {
        //   obj[key] = value
        // })
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CREDIT_NOTES_ORDERS_CLONE`, data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, creditNotesFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, creditNotesModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      creditNotesFields,
    }
  },
}

</script>
